import React from 'react';
import PropTypes from "prop-types";
import { Outline } from '@otcmarketsgroup/otcm-ui';
import { SymbolLink } from '../SymbolLink';
import styles from './SecuritiesList.module.scss';

const SecuritiesList = ({ className, accent, securityName, securities }) => <div className={`${[styles.container]} ${className}`}>
    <Outline mode='heading' accent={accent}>
        Securities
    </Outline>
    <p>
        Other {securityName} Securities
    </p>
    {securities.map(item => <SymbolLink key={item.symbol} symbol={item.symbol} entitled={item.entitled} tier={item.tier?.group} />)}
</div>;

SecuritiesList.defaultProps = {
    accent: 'green',
    securityName: ''
};

SecuritiesList.propTypes = {
    className: PropTypes.string,
    accent: PropTypes.string,
    securityName: PropTypes.string,
    securities: PropTypes.array
};

export default SecuritiesList;
