import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../context/Auth.context';
import withRouter from '../WithRouter';
import Header from '../Header';
import Footer from '../Footer';
import styles from './Layout.module.scss';

const Layout = ({ children, location, navigate }) => {
  const [authState] = useContext(AuthContext);
  const isAuthenticated = authState.isAuthenticated;
  
  useEffect(() => {
    if (location.search) {
      const newLocation = { ...location, search: '' };

      navigate(newLocation);
    }
  }, [isAuthenticated]);

  return <>
    {isAuthenticated && <div className={styles.container}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>}
  </>;
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  navigate: PropTypes.func
};


export default withRouter(Layout);
