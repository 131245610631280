import React, { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getQueryObj, redirectIQ, setSessionStorage } from '../utlis/helper';
import { logUser, validateSession } from '../api/auth';
import { storage } from '../constants';

export const AuthContext = createContext();

export const AuthConsumer = AuthContext.Consumer;

const initialState = {
  isReady: false,
  isAuthenticated: false,
  hasStorageToken: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        isAuthenticated: true
      };
    default:
      throw new Error();
  }
};

export const AuthProvider = props => {
  const [authState, authDispatch] = useReducer(reducer, initialState);
  const sessionStorageToken = window.sessionStorage.getItem(storage.TOKEN);
  const queryToken = getQueryObj(location.search).token;

  const validateUser = (token, logUserAccess) => {
    validateSession(token)
      .then(data => {
        logUserAccess && logUser(data.email);

        setSessionStorage(storage.TOKEN, token)
          .then((successMessage) => {
            console.log(successMessage);
            authDispatch({
              type: 'SET_AUTH'
            });
          })
          .catch((errorMessage) => {
            console.error(errorMessage);
          });
      })
      .catch(_ => {
        redirectIQ(); // Session Token validation failed; redirect to IQ
      });
  };

  useEffect(() => {
    if (queryToken) {
      validateUser(queryToken, true);
    } else if (sessionStorageToken) {
      validateUser(sessionStorageToken);
    } else {
      redirectIQ(); // No token and No session storage key; redirect to IQ
    }
  }, []);

  return (
    <AuthContext.Provider value={[authState, authDispatch]}>
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
