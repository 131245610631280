import api from './api';

export async function getCompanyProfile (symbol) {
    const response = await api.request({
        method: 'get',
        url: `/company/profile/full/${symbol}`,
        isOTC: true,
        params: {
            symbol: symbol
        }
    });

    return response.data;
};

export async function getInsideData (symbol) {
    const response = await api.request({
        method: 'get',
        url: `/stock/trade/inside/${symbol}`,
        isOTC: true,
        params: {
            symbol: symbol
        }
    });

    return response.data;
};

export async function getSecurities () {
    const response = await api.request({
        method: 'get',
        url: '/security/security/min',
        isOTC: true
    });

    return response.data;
};